.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#bx-modal_overlay {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.4);
  font-family: Arial,HelveticaNeue,Helvetica Neue,Helvetica,Lucida Grande,sans-serif;
}

#bx-modal_overlay .bx-modal_content {
  display: flex;
  flex-direction: column;
  position:relative;
  background-color: #F6F6F6;
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0 2px 10px 6px rgba(0,0,0,0.3);
  height: 100%;
  width: 100%;
  border: none;
}

@media screen and (min-width: 1024px) {
  #bx-modal_overlay .bx-modal_content {
      max-width: 1056px;
      height: auto;
      min-height: 100px;
      width: auto;
  }
}

#bx-modal_overlay .bx-modal_button-wrapper {
  display: flex;
  margin-top: auto;
}

#bx-modal_overlay .bx-modal_button {
  display: inline-block;
  border-radius: 4px;
  color: #fff;
  background-color: #00B377;
  padding: 10px 23px;
}

.bx-hidden {
  display: none ;
}

.bx-hidden_important {
  display: none !important;
}

/* Options Screen */
.bx-options {
  display: flex;
}

.bx-options .abper-112-text {
  flex-grow: 1;
  font-size: 14px;
  line-height: 1.5;
  margin: 16px 0 30px;
  padding-bottom: 0;
}

.abper-112-link {
  cursor: pointer;
}

/* Radio button modal */

.bx-modal_content h1 {
  font-size: 30px;
  line-height: 34px;
  font-family: EmiratesSB-Bold,Georgia,"Times New Roman",Times,serif !important;
  max-width: 90%;
  margin: auto;
  text-align: center;
}

.bx-modal_content h4 {
  color: #333;
  max-width: 60%;
  margin: 20px auto 20px;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif !important;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.2;
}

@media (max-width: 768px) {
  .bx-modal_content h4 {
    max-width: 80%;
  }
}

.bx-modal_content {
  text-align: center;
}

.bx-comment > .bx-comment_textarea {
  height: 174px;
  width: 80%;
  border: 1px solid #666;
  border-radius: 3px;
  padding: 15px 18px 16px 20px;
  resize: none;
  margin: 10px auto 10px;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.bx-comment > .bx-comment_warning {
  color: #333;
  text-align: left;
  width: 80%;
  margin: 10px auto 30px;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
}

/* Rating screen */
.bx-modal_content > #bx-rating {
  padding: 30px 20px 20px;
}

.bx-radio_label {
  display: none;
}

@media screen and (min-width: 1024px) {
  .bx-radio_label {
    right: 0;
    left: 0;
    bottom: 10px;
    position: absolute;
    font-size: 14px;
  }

  .rating .bx-star5:hover ~ .bx-label5 {
     display: block;
  }

  .rating .bx-star4:hover ~ .bx-label4 {
     display: block;
  }

  .rating .bx-star3:hover ~ .bx-label3 {
     display: block;
  }

  .rating .bx-star2:hover ~ .bx-label2 {
     display: block;
  }

  .rating .bx-star1:hover ~ .bx-label1 {
     display: block;
  }
}

/* Rating Stars */
.rating {
  display: inline-block;
  border: none;
  height: 75px;
}

/* :not(:checked) is a filter, so that browsers that don’t support :checked don’t
 follow these rules. Every browser that supports :checked also supports :not(), so
 it doesn’t make the test unnecessarily selective */
.rating:not(:checked) > input {
  position:absolute;
  top:-9999px;
  clip:rect(0,0,0,0);
}

.rating:not(:checked) > label {
  float:right;
  padding:0.3em .1em;
  overflow:hidden;
  white-space:nowrap;
  cursor:pointer;
  font-size:300%;
  line-height:1.2;
  color:#ddd;
}

.rating:not(:checked) > label:before {
  content: url(https://c.ekstatic.net/ecl/iconography/e72585a018510638901dd029c05d0724_40_38.png);
}

.rating > input:checked ~ label {
  content: url(https://c.ekstatic.net/ecl/iconography/27b9f781a94a8af00418bf50ea278016_40_38.png);
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  content: url(https://c.ekstatic.net/ecl/iconography/27b9f781a94a8af00418bf50ea278016_40_38.png);
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  content: url(https://c.ekstatic.net/ecl/iconography/27b9f781a94a8af00418bf50ea278016_40_38.png);
}

.rating > label:active {
  position:relative;
  top:2px;
  left:2px;
}

/* Comment Screen */
.bx-comment {
  padding: 30px 20px 20px;
}

/* Thank You Screen */
.bx-thank_you {
  padding: 30px 20px 20px;
}

/* close button */
.bx-modal__btn-close-icon {
  background: transparent;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  margin-top: 20px;
  margin-right: 25px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.bx-modal__btn-close-icon:after {
  content: '';
  height: 30px;
  border-left: 2px solid #75808F;
  position: absolute;
  transform: rotate(45deg);
}

.bx-modal__btn-close-icon:before {
  content: '';
  height: 30px;
  border-left: 2px solid #75808F;
  position: absolute;
  transform: rotate(-45deg);
}

/* Comment Screen */

  /*font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;*/

/* Mobile Web Feedback Button */
.abper-112-toggle .bx-mobile_web_feedback_button {
  padding: 15px 25px;
}

@media screen and (min-width: 1024px) {
  .abper-112-toggle .bx-mobile_web_feedback_button {
      padding: 0;
  }
}

/* icon */
.abper-112-toggle .icon-feedback {
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  color: white;
}

@media screen and (min-width: 1024px) {
  .abper-112-toggle .icon-feedback {
      position: absolute;
      transform: rotate(90deg) translateY(15px);
      color: #555;
  }
}
/******* EK CSS *******/

@font-face {
  font-family: ek-font-icons;
  src: url(https://cdn.ek.aero/shared/fonts/ek-icons/ek-font-icons.eot);
  src: url(https://cdn.ek.aero/shared/fonts/ek-icons/ek-font-icons.eot?#iefix) format("embedded-opentype"), url(https://cdn.ek.aero/shared/fonts/ek-icons/ek-font-icons.woff) format("woff"), url(https://cdn.ek.aero/shared/fonts/ek-icons/ek-font-icons.ttf) format("truetype"), url(https://cdn.ek.aero/shared/fonts/ek-icons/ek-font-icons.svg#ek-font-icons) format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: EmiratesSM;
  src: url(https://cdn.ek.aero/shared/fonts/emirates/emirates-medium.eot);
  src: url(https://cdn.ek.aero/shared/fonts/emirates/emirates-medium.eot?#iefix) format('embedded-opentype'), url(https://cdn.ek.aero/shared/fonts/emirates/emirates-medium.woff2) format('woff2'), url(https://cdn.ek.aero/shared/fonts/emirates/emirates-medium.woff) format('woff'), url(https://cdn.ek.aero/shared/fonts/emirates/emirates-medium.ttf) format('truetype'), url(https://cdn.ek.aero/shared/fonts/emirates/emirates-medium.svg#Emirates-Medium) format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: EmiratesSB-Bold;
  src: url(https://cdn.ek.aero/shared/fonts/emirates/emirates-bold.eot);
  src: url(https://cdn.ek.aero/shared/fonts/emirates/emirates-bold.eot?#iefix) format('embedded-opentype'), url(https://cdn.ek.aero/shared/fonts/emirates/emirates-bold.woff2) format('woff2'), url(https://cdn.ek.aero/shared/fonts/emirates/emirates-bold.woff) format('woff'), url(https://cdn.ek.aero/shared/fonts/emirates/emirates-bold.ttf) format('truetype'), url(https://cdn.ek.aero/shared/fonts/emirates/emirates-bold.svg#Emirates-Bold) format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: EmiratesSL;
  src: url(https://cdn.ek.aero/shared/fonts/emirates/emirates-light.eot);
  src: url(https://cdn.ek.aero/shared/fonts/emirates/emirates-light.eot?#iefix) format('embedded-opentype'), url(https://cdn.ek.aero/shared/fonts/emirates/emirates-light.woff2) format('woff2'), url(https://cdn.ek.aero/shared/fonts/emirates/emirates-light.woff) format('woff'), url(https://cdn.ek.aero/shared/fonts/emirates/emirates-light.ttf) format('truetype'), url(https://cdn.ek.aero/shared/fonts/emirates/emirates-light.svg#Emirates-Light) format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: heroic-condensed-black-oblique;
  src: url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-black-oblique.eot);
  src: url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-black-oblique.eot?#iefix) format("embedded-opentype"), url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-black-oblique.woff) format("woff"), url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-black-oblique.ttf) format("truetype"), url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-black-oblique.svg#heroic-condensed-black-oblique) format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: heroic-condensed-medium-oblique;
  src: url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-medium-oblique.eot);
  src: url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-medium-oblique.eot?#iefix) format("embedded-opentype"), url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-medium-oblique.woff) format("woff"), url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-medium-oblique.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: heroic-condensed-light-oblique;
  src: url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-light-oblique.eot);
  src: url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-light-oblique.eot?#iefix) format("embedded-opentype"), url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-light-oblique.woff) format("woff"), url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-light-oblique.ttf) format("truetype"), url(https://cdn.ek.aero/shared/fonts/heroic/heroic-condensed-light-oblique.svg#heroic-condensed-light-oblique) format("svg");
  font-weight: 400;
  font-style: normal;
}

.abper-112-modal.qubit-AdvancedModal {
  align-items: normal;
  left: auto;
  padding: 0;
  right: auto;
  width: 100%;
  z-index: 9999;
}
@media screen and (min-width: 1024px) {
  .abper-112-modal.qubit-AdvancedModal {
      align-items: center;
  }
}
@media (max-width: 768px) {
  .abper-112-modal .qubit-AdvancedModal-body {
      height: 100%;
  }
}
.abper-112-modal .qubit-AdvancedModal-box {
  background-color: #f6f6f6;
  display: block;
  height: 100%;
  max-height: none;
  width: 100%;
  z-index: 9999;
}
@media screen and (min-width: 1024px) {
  .abper-112-modal .qubit-AdvancedModal-box {
      height: auto;
      width: auto;
  }
}
.abper-112-modal .qubit-AdvancedModal-Icon {
  color: #333;
  font-size: 10px;
}
.abper-112-modal .qubit-VisitorPulse {
  color: #333;
  display: flex;
  font-family: HelveticaNeue, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 16px;
  flex-grow: 1;
  margin: auto;
}
.abper-112-modal .qubit-VisitorPulse-content {
  display: flex;
  margin: 0 auto;
  overflow: auto;
  padding: 30px 20px 20px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .abper-112-modal .qubit-VisitorPulse-content {
      margin: auto;
  }
}
@media screen and (min-width: 1024px) {
  .abper-112-modal .qubit-VisitorPulse-content {
      display: block;
      margin: 0 auto;
  }
}
.abper-112-modal .qubit-VisitorPulse-titleContainer {
  display: none;
}
.abper-112-modal .qubit-VisitorPulse-question {
  border-bottom: none;
  margin-bottom: 0;
  max-width: 690px;
  padding-bottom: 0;
  text-align: center;
}
.abper-112-modal .qubit-VisitorPulse-question .qubit-VisitorPulse-questionName {
  color: #333;
  font-family: EmiratesSB-Bold, Emirates-Bold, EmiratesSM, Helvetica, Arial, Lucida Grande, sans-serif !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin: 0 auto 20px;
  text-align: center;
  width: 80%;
}
@media screen and (min-width: 1024px) {
  .abper-112-modal .qubit-VisitorPulse-question .qubit-VisitorPulse-questionName {
      font-size: 30px;
      line-height: 34px;
  }
}
.abper-112-modal .qubit-VisitorPulse-question .qubit-VisitorPulse-radios {
  height: 75px;
  position: relative;
  width: 260px;
}
.abper-112-modal .qubit-VisitorPulse-question .qubit-VisitorPulse-radio input {
  display: none;
}
.abper-112-modal .qubit-VisitorPulse-question:first-child .qubit-VisitorPulse-radio {
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
  width: 20%;
}
.abper-112-modal .qubit-VisitorPulse-question:first-child .qubit-VisitorPulse-radio .qubit-VisitorPulse-radioOuter {
  background: url('https://c.ekstatic.net/ecl/iconography/e72585a018510638901dd029c05d0724_40_38.png');
  background-repeat: no-repeat;
  height: 40px;
  margin: 0;
  width: 40px;
}
.abper-112-modal .qubit-VisitorPulse-question:first-child .qubit-VisitorPulse-radio.abper-112-active .qubit-VisitorPulse-radioOuter {
  background: url('https://c.ekstatic.net/ecl/iconography/27b9f781a94a8af00418bf50ea278016_40_38.png');
  background-repeat: no-repeat;
}
.abper-112-modal .qubit-VisitorPulse-question:first-child .qubit-VisitorPulse-radio .qubit-VisitorPulse-radioInner {
  display: none;
}
.abper-112-modal .qubit-VisitorPulse-question:first-child .qubit-VisitorPulse-radio .qubit-VisitorPulse-radioText {
  bottom: 10px;
  display: none;
  font-family: HelveticaNeue, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 14px;
  font-weight: normal;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 100%;
}
.abper-112-modal .qubit-VisitorPulse-question:first-child .qubit-VisitorPulse-radio .qubit-VisitorPulse-radioText.abper-112-visible {
  display: inline-block;
}
.abper-112-modal .qubit-VisitorPulse-question:nth-child(2) {
  display: none;
}
.abper-112-modal .qubit-VisitorPulse-question:nth-child(2) .qubit-VisitorPulse-textarea {
  background: #fff;
  border: 1px solid #666;
  border-radius: 3px;
  box-shadow: inset 4px 4px 0 0 #f6f6f6;
  color: #333;
  display: block;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 16px;
  font-weight: 300;
  height: 214px;
  margin: auto;
  padding: 20px 18px 16px 20px;
}
@media screen and (min-width: 1024px) {
  .abper-112-modal .qubit-VisitorPulse-question:nth-child(2) .qubit-VisitorPulse-textarea {
      height: 174px;
      width: 80%;
  }
}
.abper-112-modal .qubit-VisitorPulse-question:nth-child(2) .abper-112-optional {
  color: #999;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  margin: 10px auto 30px;
  text-align: left;
}
@media screen and (min-width: 1024px) {
  .abper-112-modal .qubit-VisitorPulse-question:nth-child(2) .abper-112-optional {
      width: 80%;
  }
}
.abper-112-modal .qubit-VisitorPulse-question.abper-112-visible {
  display: block;
}
.abper-112-modal .qubit-VisitorPulse-question.abper-112-hide {
  display: none;
  visibility: hidden;
}
.abper-112-modal .qubit-VisitorPulse-submit {
  background-color: #c60c30;
  border-color: #c60c30;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  height: auto;
  line-height: normal;
  margin: auto 0 0;
  max-width: 690px;
  width: 100%;
}
@media screen and (min-width: 768px) {
  .abper-112-modal .qubit-VisitorPulse-submit {
      margin: 0 auto;
  }
}
@media screen and (min-width: 1024px) {
  .abper-112-modal .qubit-VisitorPulse-submit {
      width: 317px;
  }
}
.abper-112-modal .qubit-VisitorPulse-submit:hover {
  background-color: #c60c30;
  border-color: #c60c30;
  box-shadow: 0 0 6px 0 #333;
  color: #fff;
}
.abper-112-modal .qubit-VisitorPulse-submit.abper-112-visible {
  display: inline-block;
  visibility: visible;
}
.abper-112-modal .qubit-VisitorPulse-thankYou {
  flex-direction: column;
  flex-grow: 1;
  margin: 0 auto;
  max-width: 690px;
  overflow: auto;
  padding: 40px 20px 20px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .abper-112-modal .qubit-VisitorPulse-thankYou {
      margin: auto;
  }
}
@media screen and (min-width: 1024px) {
  .abper-112-modal .qubit-VisitorPulse-thankYou {
      margin: 0 auto;
      overflow: hidden;
  }
}
.abper-112-modal .qubit-VisitorPulse-thankYou .abper-112-icon-check {
  border: 2px solid green;
  border-radius: 50px;
  box-sizing: border-box;
  height: 70px;
  margin: 0 auto 30px;
  width: 70px;
}
@media screen and (min-width: 1024px) {
  .abper-112-modal .qubit-VisitorPulse-thankYou .abper-112-icon-check {
      margin: 40px auto 20px;
      height: 60px;
      width: 60px;
  }
}
.abper-112-modal .qubit-VisitorPulse-thankYou .abper-112-icon-check::before {
  border-bottom: 2px solid green;
  border-right: 2px solid green;
  box-sizing: border-box;
  content: '';
  display: inline-block;
  height: 30px;
  margin-top: -5px;
  position: relative;
  top: 50%;
  transform: rotate(45deg) translateX(-60%) translateY(-35%);
  width: 15px;
}
.abper-112-modal .qubit-VisitorPulse-thankYou .abper-112-text {
  flex-grow: 0;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (min-width: 1024px) {
  .abper-112-modal .qubit-VisitorPulse-thankYou .abper-112-text {
      margin-bottom: 30px;
  }
}
.abper-112-modal .qubit-VisitorPulse-thankYou .abper-112-close-button {
  margin: auto 0 0;
}
@media screen and (min-width: 768px) {
  .abper-112-modal .qubit-VisitorPulse-thankYou .abper-112-close-button {
      margin: 0;
  }
}
.abper-112-modal .abper-112-tripadvisor {
  border-top: 2px solid #d8d8d8;
  flex-grow: 1;
  padding: 20px 0 0;
}
.abper-112-modal .abper-112-tripadvisor.abper-112-tripadvisor-hide {
  display: none;
}
.abper-112-modal .abper-112-tripadvisor-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  height: 80px;
  margin: auto;
  width: 100px;
}
@media screen and (min-width: 1024px) {
  .abper-112-modal .abper-112-tripadvisor-logo {
      height: 50px;
      position: relative;
      top: -10px;
      width: 63px;
      vertical-align: middle;
  }
}
.abper-112-modal .abper-112-tripadvisor-copy {
  display: inline-block;
}
@media screen and (min-width: 1024px) {
  .abper-112-modal .abper-112-tripadvisor-copy {
      text-align: left;
      width: 70%;
  }
}
.abper-112-modal .abper-112-close-button {
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
  min-height: 40px;
}
@media screen and (min-width: 1024px) {
  .abper-112-modal .abper-112-close-button {
      width: 317px;
  }
}
.abper-112-modal .abper-112-text {
  flex-grow: 1;
  font-size: 14px;
  line-height: 1.5;
  margin: 16px 0 30px;
  padding-bottom: 0;
}
.abper-112-modal a,
.abper-112-modal a:hover {
  color: #333;
}
.abper-112-modal:hover,
.abper-112-modal:active,
.abper-112-modal:focus {
  outline: none !important;
}
.abper-112-modal *:focus {
  outline: none !important;
}
.abper-112-toggle {
  border-top: 1px solid rgba(255, 255, 255, .13);
  margin-top: auto;
}
.abper-112-toggle:hover,
.abper-112-toggle:active,
.abper-112-toggle:focus {
  outline: none;
}
.abper-112-toggle+.country-selector-mobile {
  margin-top: 0;
}
.abper-112-toggle .abper-112-text {
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: HelveticaNeue, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 14px;
  position: relative;
  vertical-align: middle;
}
@media screen and (min-width: 1024px) {
  .abper-112-toggle .abper-112-text {
      color: #333;
      width: auto;
  }
}
@media screen and (min-width: 1024px) {
  .abper-112-toggle {
      background-color: #fff;
      border-radius: 3px 3px 0 0;
      box-shadow: 0 1px 4px 1px #666;
      color: #333;
      cursor: pointer;
      font-family: HelveticaNeue, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
      font-size: 14px;
      height: 40px;
      overflow: hidden;
      padding: 10px 15px 25px 25px;
      position: fixed;
      right: 0;
      top: calc(50% - 120px);
      transform: rotate(-90deg);
      transform-origin: 100% 100%;
      z-index: 99;
  }
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .abper-112-toggle {
      top: calc(25% - 120px);
  }
}
.abper-112-toggle .abper-112-toggle-container {
  position: relative;
}
.abper-112-toggle .abper-112-icon {
  background-image: url('https://c.ekstatic.net/ecl/iconography/4fb725e86a700af2f5a185035d78d864_48_42.png');
  background-size: cover;
  display: inline-block;
  height: 22px;
  vertical-align: middle;
  width: 25px;
}
@media screen and (min-width: 1024px) {
  .abper-112-toggle .abper-112-icon {
      background-image: url('https://c.ekstatic.net/ecl/iconography/9a9731bdeeac7dabae8183d63729c015_81_68.png');
      height: 20px;
      position: absolute;
      transform: rotate(90deg) translateY(15px);
  }
}
.abper-112-toggle .abper-112-text {
  display: none;
  line-height: 20px;
  padding: 0 0 0 15px;
}
.qubit-VisitorPulse-modal {
  display: none;
}
#abper-112-feedback-content {
  font-family: HelveticaNeue, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  margin: 0 auto;
  max-width: 690px;
  padding: 70px 10px;
  text-align: center;
  width: 100%;
}
@media screen and (min-width: 768px) {
  #abper-112-feedback-content {
      margin: auto;
  }
}
@media screen and (min-width: 1024px) {
  #abper-112-feedback-content {
      display: flex;
      margin: 0 auto;
      max-width: 1056px;
      padding: 60px 40px;
  }
}
#abper-112-feedback-content .abper-112-feedback,
#abper-112-feedback-content .abper-112-contact,
#abper-112-feedback-content .abper-112-complaint {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.19);
  display: block;
  margin-bottom: 20px;
  padding: 10px 10px 0;
  position: relative;
  text-decoration: none;
}
#abper-112-feedback-content .abper-112-feedback span.abper-112-button,
#abper-112-feedback-content .abper-112-contact span.abper-112-button,
#abper-112-feedback-content .abper-112-complaint span.abper-112-button {
  pointer-events: none;
}
@media screen and (min-width: 1024px) {
  #abper-112-feedback-content .abper-112-feedback,
  #abper-112-feedback-content .abper-112-contact,
  #abper-112-feedback-content .abper-112-complaint {
      background: none;
      border-radius: 0;
      box-shadow: none;
      display: flex;
      flex-basis: auto;
      flex-direction: column;
      margin: 0;
      padding: 30px;
      width: 33.3333%;
  }

  #abper-112-feedback-content .abper-112-feedback::after,
  #abper-112-feedback-content .abper-112-contact::after,
  #abper-112-feedback-content .abper-112-complaint::after {
      border-right: 1px solid #d8d8d8;
      content: "";
      display: block;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
  }
}
#abper-112-feedback-content .abper-112-title {
  text-align: center;
}
#abper-112-feedback-content .abper-112-title,
#abper-112-feedback-content .abper-112-hr,
#abper-112-feedback-content .abper-112-text {
  display: none;
}
@media screen and (min-width: 1024px) {
  #abper-112-feedback-content .abper-112-title,
  #abper-112-feedback-content .abper-112-hr,
  #abper-112-feedback-content .abper-112-text {
      display: inline-block;
  }
}
#abper-112-feedback-content .abper-112-complaint::after {
  border: none;
}
@media screen and (max-width: 1023px) {
  #abper-112-feedback-content .abper-112-button,
  #abper-112-feedback-content .abper-112-button:hover {
      background: none;
      border: none;
      box-shadow: none;
      color: #333;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 6px;
      width: 100%;
  }
}
#abper-112-feedback-content .abper-112-icon {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 55px;
  margin: 0 auto;
  width: 60px;
}
@media screen and (min-width: 1024px) {
  #abper-112-feedback-content .abper-112-icon {
      height: 68px;
      margin: 0 auto 20px;
      width: 80px;
  }
}
#abper-112-feedback-content .abper-112-icon-feedback {
  background-image: url('https://c.ekstatic.net/ecl/iconography/9a149a9f19b5506ca8a908f23f01c24f_60_60.png');
  height: 34px;
  width: 34px;
}
@media screen and (min-width: 1024px) {
  #abper-112-feedback-content .abper-112-icon-feedback {
      background-image: url('https://c.ekstatic.net/ecl/iconography/9a9731bdeeac7dabae8183d63729c015_81_68.png');
      height: 68px;
      width: 81px;
  }
}
#abper-112-feedback-content .abper-112-icon-contact {
  background-image: url('https://c.ekstatic.net/ecl/iconography/dcb2d20bb523a4d1b635194758896024_60_60.png');
  height: 34px;
  width: 34px;
}
@media screen and (min-width: 1024px) {
  #abper-112-feedback-content .abper-112-icon-contact {
      background-image: url('https://c.ekstatic.net/ecl/iconography/c8be9d69c9aa7b9c193ce1049e616f36_81_68.png');
      height: 68px;
      width: 81px;
  }
}
#abper-112-feedback-content .abper-112-icon-complaint {
  background-image: url('https://c.ekstatic.net/ecl/iconography/f633a3e3102979ba5c912a4b09c74c86_60_60.png');
  height: 34px;
  width: 34px;
}
@media screen and (min-width: 1024px) {
  #abper-112-feedback-content .abper-112-icon-complaint {
      background-image: url('https://c.ekstatic.net/ecl/iconography/fbc91a6287d56b2f84607070934ba7d8_81_68.png');
      height: 68px;
      width: 81px;
  }
}
#abper-112-feedback-content .abper-112-title,
.abper-112-thank-you .qubit-VisitorPulse-thankYouText {
  color: #333;
  font-family: EmiratesSB-Bold, Emirates-Bold, EmiratesSM, Helvetica, Arial, Lucida Grande, sans-serif !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}
@media screen and (min-width: 1024px) {
  #abper-112-feedback-content .abper-112-title,
  .abper-112-thank-you .qubit-VisitorPulse-thankYouText {
      font-size: 30px;
      line-height: 34px;
  }
}
.abper-112-modal .qubit-VisitorPulse-thankYouText {
  font-size: 30px;
  line-height: 1.25;
  margin: 0;
}
hr.abper-112-hr {
  background: none;
  border: none;
  border-top: 2px solid #c60c30;
  display: inline-block;
  margin: 0 auto;
  width: 60px;
}
.abper-112-button,
.qubit-VisitorPulse-submit {
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 3px;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 20px;
  padding: 11px;
  text-align: center;
  text-decoration: none;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .abper-112-button,
  .qubit-VisitorPulse-submit {
      width: auto;
  }
}
.abper-112-button:hover,
.qubit-VisitorPulse-submit:hover {
  box-shadow: 0 0 6px 0 #666;
}
.abper-112-button.abper-112-primary,
.qubit-VisitorPulse-submit.abper-112-primary {
  background-color: #c60c30;
  border-color: #c60c30;
  color: #fff;
  font-family: HelveticaNeue, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 14px;
  font-weight: 700;
}
.abper-112-button.abper-112-primary:hover,
.qubit-VisitorPulse-submit.abper-112-primary:hover {
  background-color: #c60c30;
  border-color: #c60c30;
  color: #fff;
}
.abper-112--focus {
  border-radius: 2px !important;
  box-shadow: 0 0 2px 1.5px #fff, 0 0 3px 3px #000, inset 0 0 0.5px 0.5px #fff, inset 0 0 0.5px 1.5px #000 !important;
  outline: 0 !important;
}
.qubit-VisitorPulse-hidden {
  visibility: hidden;
}
@media (max-width: 480px) {
  .ekglobal-navigation-megamenu-holder.menu--opened {
      overflow-y: scroll;
  }
}
@media (orientation: portrait) {
  .abper-112-modal .qubit-VisitorPulse {
      display: block;
  }

  .abper-112-modal .qubit-AdvancedModal-body {
      flex: none;
      height: 100%;
  }

  .abper-112-modal .qubit-VisitorPulse {
      display: block;
  }

  .abper-112-modal .qubit-VisitorPulse-content {
      height: 100%;
      position: relative;
  }

  .abper-112-modal .qubit-VisitorPulse-submit.abper-112-visible {
      bottom: 30px;
      left: 20px;
      right: 20px;
      position: absolute;
      width: auto;
  }

  .abper-112-modal .qubit-VisitorPulse-thankYou {
      height: 100%;
      position: relative;
  }
}
@media (orientation: portrait) and (min-width: 768px) {
  .abper-112-modal .qubit-VisitorPulse-submit.abper-112-visible {
      bottom: 60px;
      left: 30px;
      right: 30px;
  }
}
@media (orientation: portrait) and (min-width: 1024px) {
  .abper-112-modal .qubit-VisitorPulse-submit.abper-112-visible {
      bottom: auto;
      left: auto;
      position: relative;
      right: auto;
      width: 317px;
  }
}
@media (orientation: portrait) and (min-width: 768px) {
  .abper-112-modal .abper-112-button.abper-112-primary.abper-112-close-button {
      bottom: 60px;
      left: 0;
      max-width: 690px;
      position: absolute;
  }
}
@media (orientation: portrait) and (min-width: 1024px) {
  .abper-112-modal .abper-112-button.abper-112-primary.abper-112-close-button {
      bottom: auto;
      left: auto;
      position: relative;
      right: auto;
  }
}
.ek .abper-112-modal [contentEditable=true]:not([tabindex='-1']):not(.panel-disabled):focus,
.ek .abper-112-modal [tabindex]:not([tabindex='-1']):not(.panel-disabled):focus,
.ek .abper-112-modal a:not([tabindex='-1']):not(.panel-disabled):focus,
.ek .abper-112-modal area:not([tabindex='-1']):not(.panel-disabled):focus,
.ek .abper-112-modal button:not([disabled]):not([tabindex='-1']):not(.panel-disabled):focus,
.ek .abper-112-modal iframe:not([tabindex='-1']):not(.panel-disabled):focus,
.ek .abper-112-modal input:not([disabled]):not([tabindex='-1']):not(.panel-disabled):focus,
.ek .abper-112-modal select:not([disabled]):not([tabindex='-1']):not(.panel-disabled):focus,
.ek .abper-112-modal textarea:not([disabled]):not([tabindex='-1']):not(.panel-disabled):focus {
  outline: none !important;
}
.ek .abper-112-modal p a {
  text-decoration: underline;
}
.ek .abper-112-modal p a:hover {
  text-decoration: none;
}
@media screen and (min-width: 1024px) {
  .ek-global-navigation .abper-112-toggle[tabindex="0"]:focus {
      border-radius: 3px 3px 0 0 !important;
      box-shadow: 0 1px 4px 1px #666 !important;
  }
}
.abper-112-toggle[tabindex]:focus:not(a):not(button) {
  border-radius: 0;
  box-shadow: none;
}
@media screen and (min-width: 1024px) {
  .abper-112-toggle[tabindex]:focus:not(a):not(button) {
      border-radius: 3px 3px 0 0;
      box-shadow: 0 1px 4px 1px #666;
  }
}
.abper-112-modal a:focus {
  border-radius: none;
  box-shadow: none;
}
.page-template.leaderboard-template .abper-112-toggle {
  height: 5px;
}
.ekglobal-navigation-megamenu-holder.menu--opened .abper-112-toggle {
  height: auto;
}
.abper-112-modal label.qubit-VisitorPulse-radio * {
  pointer-events: none;
}
.abper-112-modal * {
  -webkit-tap-highlight-color: transparent;
}
.abper-112-toggle {
  display: none;
}
@media screen and (min-width: 1024px) {
  .abper-112-toggle {
      display: block;
  }
}
.abper-112-toggle+.nav__lang-wrapper {
  margin-top: 0;
}
.nav--show .abper-112-toggle {
  display: block;
}
.ek-global-navigation .menu--opened .abper-112-toggle,
.main-navigation__nav .nav--mobile .abper-112-toggle {
  display: block;
}
@media screen and (min-width: 1024px) {
  .ek-global-navigation .menu--opened .abper-112-toggle,
  .main-navigation__nav .nav--mobile .abper-112-toggle {
      display: none;
  }
}
.ek-global-navigation .menu--opened .abper-112-toggle+div,
.main-navigation__nav .nav--mobile .abper-112-toggle+div {
  display: block;
}
.main-navigation__nav .nav--mobile .abper-112-toggle-container {
  padding: 15px;
}
.abper-112-hidden-mobile {
  display: none !important;
}
@media screen and (min-width: 1024px) {
  .abper-112-hidden-mobile {
      display: inline-block !important;
  }
}
.abper-112-hidden-desktop {
  display: inline-block !important;
}
@media screen and (min-width: 1024px) {
  .abper-112-hidden-desktop {
      display: none !important;
  }
}

#bx-contact_link3 {
  text-decoration: underline !important;
}

#bx-contact_link3:hover {
  text-decoration: none !important;
  background-color: #f6f6f6;
}

.background {
  background-image:  url("https://c.ekstatic.net/ecl/skywards/skywards-tree-blue-w1920x480.jpg?h=2ZrGQTqpgxZNt8LEalaN4w");
}

.db{
  display: block;
}

.myaccount1
{
  overflow: hidden; 
  visibility: visible; 
  justify-content: space-evenly;
}
.widget-container__widget-wrapper {
  display:flex;
  flex-direction:column;
  justify-content:stretch;
  width:100%;
  padding:10px;
  background-color:#fff;
  border-radius:3px;
  box-shadow:0 2px 4px 2px rgba(0,0,0,.1)
 }
 @media only screen and (min-width:992px) {
  .widget-container__widget-wrapper {
   max-width:1100px;
   padding:20px
  }
 }
.date-input-range-picker,
.date-input-range-picker {
 box-shadow:inset 4px 4px 0 0 #f2f2f2
}
.date-input-range-picker {
  position:relative;
  display:inline-block;
  box-sizing:border-box;
  width:100%;
  min-height:60px;
  overflow:hidden;
  color:#333;
  line-height:60px;
  background:#fff;
  border-radius:3px;
  position:static;
  padding:0;
  overflow:visible;
  line-height:normal;
  background-color:#fff;
  border:1px solid #666
 }
.DateInput,
.DateInput_inner-wrapper {
 position:relative
}
.booking-widget__second-row__column {
  flex: 1;
  margin-bottom: 0;
}
.date-input-range-picker, [dir] .date-input-range-picker {
  box-shadow: inset 4px 4px 0 0 #f2f2f2;
}
.date-input-range-picker {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  min-height: 60px;
  overflow: hidden;
  color: #333;
  line-height: 60px;
  background: #fff;
      background-color: rgb(255, 255, 255);
  border-radius: 3px;
  position: static;
  padding: 0;
  overflow: visible;
  line-height: normal;
  background-color: #fff;
  border: 1px solid #666;
}
.date-input-range-picker .DateInput_label__focused {
  top: 0;
  bottom: inherit;
  padding-top: 6px;
  font-size: 1.2rem;
  line-height: 20px;
}
.DateInput_label {
  height:60px;
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
  z-index:2;
  display:block;
  padding:0 20px;
  overflow:hidden;
  font-weight:400;
  font-size:1.6rem;
  line-height:60px;
  white-space:nowrap;
  text-overflow:ellipsis;
  transition:all .2s ease;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  font-family:HelveticaNeue-Light,Helvetica Neue Light,Helvetica Neue,Helvetica,Arial,Lucida Grande,sans-serif
 }
 .DateRangePicker .DateRangePickerInput {
  position: relative;
  display: flex;
  align-items: center;
}
.DateRangePicker .DateInput {
  width: 50%;
  height: 58px;
  background: transparent;
  border: none;
  box-shadow: none !important;
}
.DateInput {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  color: #333;
  line-height: 60px;
  background: #fff;
  border: 1px solid #666;
  border-radius: 3px;
  box-shadow: inset 3px 3px 4px #f2f2f2;
}
.DateInput_screenReaderMessage {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0,0,0,0);
}
.DateRangePicker .DateInput_input {
  z-index: 2;
}
.booking-widget .DateInput_input, .booking-widget .DateInput_label {
  cursor: pointer;
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.DateInput_input {
  font-family: HelveticaNeue-Medium,Helvetica,Arial,Lucida Grande,sans-serif;
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  font-size: 2rem;
  line-height: 40px;
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
  top: 10px;
  padding: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.DateInput_label__multi:last-of-type {
  left: 50%;
}
.DateInput_label__multi {
  width: 50%;
}

 .icon-chevron-down::before {
  content: "\f148";

}
.station-suggest-item__emirates {
  max-width: 58px;
  height: 20px;
  font-size: 1rem;
  font-style: normal;
  white-space: normal;
  vertical-align: top;
  word-wrap: break-word;
}
.station-suggest-item__icons {
  display: flex;
  height: 100%;
  line-height: 20px;
  white-space: nowrap;
}
.station-suggest-item__long-name {
  display: flex;
  padding: 3px 0 0;
  font-size: 1.2rem;
  font-style: normal;
}
.station-suggest-item__info {
  flex-grow: 1;
  flex-basis: 0;
  width: 100%;
  text-align: left;
}
.icon-profile-account::before
{
  font-size: 20px;
}
.ou0{
  background: "#fef8d5";
}
.ou1 {
  width: 100%; 
  color: #666; 
  padding: 0 15px;
}
.ou2 {
  max-width: 1100px; 
  position: relative; 
  border-bottom: 2px solid #f4eece; 
  padding: 0;
}
.ou3 {
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif; 
  font-size: 1.4rem; 
  line-height: 1.61rem;
  font-weight: 200;
  display: inline-block;
  padding: 0 110px 0 25px;
  color: #333;
  font-weight: normal;
  margin: 14px 0px;
}
.ou4 {
  font-weight: 700;
}
.ou5 {
  position: absolute;
  top: 1.5rem;
  left: 0;
  font-size: 1.5rem;
}
.ou6
{
  color: #c60c30;
}
.ou7 {
  color: #333;
  font-weight: normal;
}
.ou8
{
  position: absolute;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1.4rem;
  line-height: 1.61rem;
  font-weight: 200;
  top: 1.4rem;
  right: 0;
  max-width: 30vw;
  text-align: center;
}
.ou9
{
  color: #333;
  font-weight: normal;
  text-decoration: none;
}
.ou10
{
  text-decoration: underline;
}
.ou11
{
  font-size: 10px;
  margin-left: 5px;
}
.ou12
{
  color: #333;
  font-weight: normal;
  text-decoration: none;
}
.ou13
{
  text-decoration: underline;
}
.ou14
{
  font-size: 10px;
  margin-left: 5px;
}
.operational-update--displayed{display:block!important}
.op-hidden{display:none}
.not-first__label{padding:0 25px!important}
.operational-updates-ticker 
.operational-update.operational-update--not-dismissed{display:block!important}
.operational-updates-ticker--enable{animation:ticker 10s cubic-bezier(1,0,.5,0) infinite;animation-delay:3s}
.operational-updates-ticker--enable:hover{animation-play-state:paused}
[dir=rtl] .operational-update__label .operational-update__label--bold{padding:0 0 0 5px!important}
[dir=rtl] .operational-update__label--bold{padding:0 25px 0 10px!important;float:right}
[dir=rtl] .operational-update__label--bold .icon{right:0;left:auto!important}
[dir=rtl] .operational-updates-ticker__wrapper{margin-left:25px;margin-right:0!important}
[dir=rtl] .operational-update__wrapper .operational-update__dismiss{left:0;right:auto!important}
[dir=rtl] .operational-update__wrapper .operational-update__label{padding:0 25px 0 105px!important}
[dir=rtl] .operational-update__expander{right:auto!important;left:0}[dir=rtl] 
.operational-update__wrapper .operational-update__label.not-first__label{padding:0 25px!important}
[dir=rtl] .expander-with__close.operational-update__expander{left:20px!important}

.wc1
{
  margin: 0 auto;
}
.wi4
{
  width: 100%;
  background-color: white;
  border-radius: 0.3rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0,0,0,.19);
}
.pointer
{
  cursor: pointer;
}
.ek-datepicker__day a:hover {  
  border-radius: 4px;
  border: 1px solid #c60c30;
  background: #c60c30;
  color: #fff;
  font-weight: 700;
}

.ek-datepicker__day.ek-datepicker__day--inactive a:hover {
  text-decoration: none;
  color: #666;
  background-color: #f6f6f6;
}

.bx-skywards-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.bx-skywards-login-container {
  max-width: 1100px;
  margin: 25px auto;
  flex-direction: row;
  justify-content: space-between;
}
.bx-skywards-login-tier-logo {
  width: 75px;
  height: 75px;
  border: 5px solid var(--white-color);
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 1;
  position: relative;
}
.bx-skywards-login-tier-logo {
  left: 20px;
  border: 5px solid var(--white-color);
  position: relative;
  z-index: 1;
  min-width: 90px;
  min-height: 90px;
}
.bx-skywards-login-body {
  font-family: var(--font-helvetica);
  font-size: 20px;
  line-height: 24px;
  color: white;
  text-align: center;
}
.bx-skywards-login-body {
  font-family: var(--font-helvetica);
  font-size: 20px;
  line-height: 24px;
  color: white;
  text-align: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #000;
  z-index: 0;
  border-radius: 5px;
}
.bx-skywards-login-body {
  display: flex;
  align-items: center;
  padding: 10px 20px 10px 130px;
  margin-left: -90px;
  min-height: 65px;
  min-width: 100%;
  justify-content: space-between;
  text-align: left;
}
.bx-skywards-background {
  position: absolute;
  top: 0;
  width: 100%;
  right: 0;
  opacity: .3;
}
.bx-skywards-signup-body-container {
  display: flex;
  flex-direction: column;
}
.bx-skywards-login__cta {
  margin-left: 20px;
}
.bx-skywards-login__cta {
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  min-width: 150px;
  padding: 5px 20px;
  font-size: 1.4rem;
  line-height: 1.8rem;
  box-shadow: 0 2px 1px 0 rgba(0,0,0,.1);
  border: .1rem solid white;
  background: white;
  color: #333;
  text-decoration: none;
  appearance: none !important;
  white-space: normal;
  font-style: normal;
  border-radius: .3rem;
  font-family: HelveticaNeue,Helvetica Neue,Helvetica,Lucida Grande,sans-serif;
  z-index: 1;
}
.bx-skywards-login-title {
  font-weight: 700;
  margin: 0 0 15px 0;
}
.bx-skywards-signup-title {
  font-size: 16px;
  line-height: 20px;
}
.bx-skywards-login-title {
  margin: 0;
}
.bx-skywards-signup-body {
  font-size: 14px;
  line-height: 18px;
}
.bx-skywards-body {
  font-family: var(--font-helvetica);
  font-size: 20px;
  line-height: 24px;
  color: var(--white-color);
}
.bx-skywards-background {
  position: absolute;
  top: 0;
  width: 100%;
  right: 0;
  opacity: .3;
}
.bx-skywards-signup-body {
  font-size: 14px;
  line-height: 18px;
}
